<template>
  <div class="flex flex-col items-center m-8 gap-4">
    <button
      class="border border-grey-2 sm:w-4/6 w-4/5 px-8 py-4 text-xl font-medium hover:bg-black hover:text-white hover:border-transparent"
      @click="$router.push({ name: 'FaqUser' })"
    >
      TRIPWE APP
    </button>
    <button
      class="border border-grey-2 sm:w-4/6 w-4/5 px-8 py-4 text-xl font-medium hover:bg-black hover:text-white hover:border-transparent"
      @click="$router.push({ name: 'FaqMerchant' })"
    >
      Merchant
    </button>
  </div>
</template>
<script>
export default {};
</script>
